import { Auth } from 'Shared/auth';
import { onElementExists } from 'Shared/utils';

onDomReady(function () {
   if (Auth.isLoggedIn() || $('errorName').get('text') !== 'e403' || App.disableAutoLogin) {
      return;
   }

   onElementExists('.header-link__login', () => {
      Auth.required({
         from: 'navLogin',
         message: '',
         locked: false,
         ignoreSSO: App.onManage,
         stayInWindow: true,
         onAuthorize: function () {
            window.location.reload();
         },
      });
   });
});
